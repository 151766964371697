// ** Reactstrap Imports
import { Card, CardBody, Button, Table } from 'reactstrap'

// ** Avatar Import
import Avatar from '@components/avatar'

// ** Icons
import { Plus } from 'react-feather'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'

// ** Tab Options
const innerTabOptions = [
  { value: 1, title: 'İşe Giriş-Çıkış Dökümü' },
  { value: 2, title: 'Viziteleri' },
  { value: 3, title: 'İş Kazaları' }
]

// ** Main Component
const WorkerInfoCard = ({ workerData, activeWorkerSubTab, setActiveWorkerSubTab }) => {
  const handleTabClick = (value) => {
    setActiveWorkerSubTab(value)
  }

  if (!workerData) {
    return <div>Loading...</div>
  }
console.log(workerData)
  const renderUserImg = (workerData) => {
    return (
      <div className='d-flex align-items-center flex-column'>
        {workerData?.avatar ? (
          <img
            height='110'
            width='110'
            alt='user-avatar'
            src={workerData.avatar}
            className='img-fluid rounded mt-3 mb-2'
          />
        ) : (
          <Avatar
            initials
            color={workerData?.avatarColor || 'light-primary'}
            className='rounded mt-3 mb-2'
            content={`${workerData.firstname} ${workerData.lastname}`}
            contentStyles={{
              borderRadius: 0,
              fontSize: 'calc(48px)',
              width: '100%',
              height: '100%'
            }}
            style={{
              height: '110px',
              width: '110px'
            }}
          />
        )}
        <div className='text-center mt-1'>
          <h5>{`${workerData.firstname} ${workerData.lastname}`}</h5>
          <p>{workerData.jobName}</p>
        </div>
      </div>
    )
  }

   return (
    <Card>
      <CardBody>
        <div className='user-avatar-section'>
          <div className='d-flex align-items-center flex-column'>
            {renderUserImg(workerData)}
          </div>
        </div>
        <div className='info-container d-flex justify-content-center'> {/* Tablonun ortalanması için flexbox */}
          <Table borderless size={'sm'} responsive>
            <tbody>
              <tr>
                <td className="text-start text-nowrap">Kimlik Numarası</td>
                <td className="text-center ps-2">:</td>
                <td className="text-start ps-2">{workerData.identityNumber}</td>
              </tr>
              <tr>
                <td className="text-start pe-2 text-nowrap">Doğum Tarihi</td>   
                <td className="text-center ps-2">:</td>
                <td className="text-start ps-2">{workerData.birthDate}</td>
              </tr>
              <tr>
                <td className="text-start pe-2 text-nowrap">Uyruk</td>
                <td className="text-center ps-2">:</td>
                <td className="text-start ps-2">{workerData.nationality}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className='d-flex flex-column mt-2'>
          {innerTabOptions.map((item) => (
            <Button
              key={item.value}
              color={activeWorkerSubTab === item.value ? 'primary' : 'secondary'}
              outline={activeWorkerSubTab !== item.value}
              className='me-1 mb-1'
              onClick={() => handleTabClick(item.value)}
            >
              <div className='d-flex justify-content-between'>
                <Plus size={14} /> 
                <span className='align-middle justify-content-end ms-50'>{item.title}</span>
              </div>
            </Button>
          ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default WorkerInfoCard
