// ** React Imports
import React, { useEffect, useState} from 'react'

// ** Redux Imports
import { useDispatch, useSelector} from 'react-redux'
import {getWorkerEntryOutDetails} from '../../store/index'

// ** Third Party Components
import {Card} from "reactstrap"
import DataTable from 'react-data-table-component'

// ** moment Import For Date Formatting
import moment from 'moment/moment'

// ** Columns
import { entryOutTableColumns } from './EntryOutTableColumns'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'


function  EntryOutTable({workerData}) {
    const {selectedCompanyGroup} = useSelector(state => state.auth)
    const [entryOutTableData, setEntryOutTableData] = useState(null)
    const [newData, setNewData] = useState(null) // ** Mapped entryOutTableData with additional fields for rendering
    const dispatch = useDispatch()

    // ** Query params for distpatch
    const query = {
        CompanyGroupId:selectedCompanyGroup,
        IdentificationNumber:workerData?.identityNumber,
        SortColumn:'startDate',
        Sort: 'desc'
    }

    // ** Get data on mount
    useEffect(() => {
        const fetchWorkerEntryOutDetails = async () => {
            try {
            const action = await dispatch(getWorkerEntryOutDetails(query)).unwrap()
            setEntryOutTableData(action)
                } catch (error) {
                console.error('Failed to fetch worker entry out details:', error)
                }
            }
        fetchWorkerEntryOutDetails()
    }, [])

    // ** Map entryOutTableData with additional fields for rendering
    useEffect(() => {
        const newData = entryOutTableData && entryOutTableData.map((item, index) => {
            const startDate = moment(item.startDate, 'DD.MM.YYYY')
            const endDate = item.endDate ? moment(item.endDate, 'DD.MM.YYYY') : moment() // endDate yoksa bugünü al
            const endDateToRender = item.endDate ? item.endDate : 'AKTİF'
            const days = endDate.diff(startDate, 'days')
            
            return {
                ...item,
                index,
                days,
                nameSurname:`${item.firstname  } ${  item.lastname}`,
                endDate: endDateToRender
            }
            })
            setNewData(newData)
        }, [entryOutTableData])

  return (
          <Card className='overflow-hidden'>
              <div className='react-dataTable' style={{height: 480, width: '100%'}}>
                  {newData && (
                     <DataTable
                        noHeader
                        responsive
                        columns={entryOutTableColumns}
                        className='react-dataTable h-100'
                        data={newData}
                        fixedHeader
                        fixedHeaderScrollHeight="500px" 
                   />
                   
                  )}
              </div>
          </Card>
  )
}

export default EntryOutTable