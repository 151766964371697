// ** React Imports
import { useState, useEffect } from 'react'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { getWorkerDetails } from './../store/index'

// ** UseLocation Hook
import { useLocation } from 'react-router-dom'

// ** Sub Components
import WorkerInfoCard from './WorkerInfoCard'
import WorkerSubTabs from './WorkerSubTabs'

// ** Reactstrap Components
import { Row, Col } from 'reactstrap'

// ** Main Component
const WorkerView = ({data}) => {
  // ** Local States
  const [workerData, setWorkerData] = useState(null)
  const [activeWorkerSubTab, setActiveWorkerSubTab] = useState(0)
  const location = useLocation()

  // ** Dispatch
  const dispatch = useDispatch()

  // ** Get Worker Details on mount
  useEffect(() => {
    const fetchWorkerDetails = async () => {
      try {
        const action = await dispatch(getWorkerDetails(data)).unwrap()
        setWorkerData(action) 
      } catch (error) {
        console.error('Failed to fetch worker details:', error)
      }
    }

    // ** İş kazası veya vizite sayfalarında isek, ilgili subtab'ı aktif hale getir
    if (location.pathname.endsWith("visit")) {
      setActiveWorkerSubTab(2)
    } else if (location.pathname.endsWith("is-kazasi")) {
      setActiveWorkerSubTab(3)
    }

    fetchWorkerDetails()
  }, [data, dispatch])


return  (
    <div className='app-user-view'>
      <Row>
        <Col xl='3' lg='4' xs={{ order: 1 }} md={{ order: 0, size: 4 }}>
          <WorkerInfoCard workerData={workerData} activeWorkerSubTab={activeWorkerSubTab} setActiveWorkerSubTab={setActiveWorkerSubTab}/>
        </Col>
        <Col xl='9' lg='8' xs={{ order: 0 }} md={{ order: 1, size: 8 }}>
         {workerData && <WorkerSubTabs workerData={workerData} activeWorkerSubTab={activeWorkerSubTab}  />}
        </Col>
      </Row>
    </div>
  ) 
}
export default WorkerView
