// ** React Imports
import React, { useEffect, useState} from 'react'

// ** Redux Imports
import { useDispatch, useSelector} from 'react-redux'
import {getWorkerVisitDetails} from '../../store/index'

// ** Third Party Components
import {Card} from "reactstrap"
import DataTable from 'react-data-table-component'

// ** Columns
import { visitsTableColumns } from './VisitsTableColumns'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** Permission Control
import { getModuleIdsByCompanyGroupId } from '../../../../../auth/utils'


function  VisitsTable({workerData}) {
    const {selectedCompanyGroup, userData } = useSelector(state => state.auth)
    const [visitsTableData, setVisitsTableData] = useState(null)
    const [newData, setNewData] = useState(null) // ** Mapped visitsTableData with additional fields for rendering
    const dispatch = useDispatch()
    const moduleIds = getModuleIdsByCompanyGroupId(userData, selectedCompanyGroup)
    const hasPermission = moduleIds.includes(6) 

    // ** Query params for distpatch
    const query = {
        CompanyGroupId:selectedCompanyGroup,
        IdentificationNumber:workerData?.identityNumber
    }

    // ** Get data on mount
    useEffect(() => {
        const fetchWorkerEntryOutDetails = async () => {
            try {
            const action = await dispatch(getWorkerVisitDetails(query)).unwrap()
            setVisitsTableData(action)
                } catch (error) {
                console.error('Failed to fetch worker entry out details:', error)
                }
            }
        fetchWorkerEntryOutDetails()
    }, [])

    // ** Map entryOutTableData with additional fields for rendering
    useEffect(() => {
        const newData = visitsTableData && visitsTableData.map((item, index) => {
            return {
                ...item,
                index,
                nameSurname:`${item.firstname  } ${  item.lastname}`
            }
            })
            setNewData(newData)
            console.log(newData)
        }, [visitsTableData])

    if (!hasPermission) {
        return (
            <Card className='overflow-hidden'>
            <div className='react-dataTable' style={{height: 480, width: '100%'}}>
                <div className='d-flex justify-content-center align-items-center'>
                    <h4 className='rounded-pill mb-0' style={{ backgroundColor: '#f8d7da', color: '#b71c1c', padding:'5px 10px'}}>
                        Vizite Modülü Kullanılmamaktadır
                    </h4>
                </div>
            </div>
        </Card> 
        )
    }    

  return (
          <Card className='overflow-hidden'>
              <div className='react-dataTable' style={{height: 480, width: '100%'}}>
                  {newData && (
                     <DataTable
                        noHeader
                        responsive
                        columns={visitsTableColumns}
                        className='react-dataTable h-100'
                        data={newData}
                        fixedHeader
                        fixedHeaderScrollHeight="500px" 
                   />
                   
                  )}
              </div>
          </Card>
  )
}

export default VisitsTable