// ** React Imports
import {store} from "@store/store"
import {getCompanyDetails, ToggleHtmlPopup, ToggleVisitHtmlImagePopup} from "../store"
// ** Icons Imports
// import { Settings, FileText, Trash2 } from 'react-feather'

// ** Reactstrap Imports
import {File} from "react-feather"
import {Button} from "reactstrap"
import { createWorkerDetailsTab } from "../../workerlist/store"

export const columns = [
    {
        name: "KOD",
        sortable: true,
        minWidth: "180px",
        sortField: "CompanyName",
        selector: row => row.companyName,
        cell: row => row.companyName,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },


    {
        name: "İŞYERİ",
        sortable: true,
        minWidth: "180px",
        sortField: "registrationNumber",
        selector: row => row.registrationNumber,
        when: row => row.notStaff === "Evet",
        cell: (row) => <button className="btn btn-danger"
                               onClick={() => store.dispatch(getCompanyDetails({
                                   id: row.companyId,
                                   name: "FirmaDetay1",
                                   text: row.companyName
                               }))}
                               id={row.registrationNumber}>{row.registrationNumber ? row.registrationNumber : ""}</button>,
        button: true,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#fffff"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KİMLİK NO",
        sortable: true,
        minWidth: "200px",
        sortField: "identificationNo",
        selector: row => row.identificationNo,
        //cell: row => row.identificationNo
        cell: (row) => <button className="btn btn-danger" id={row.identificationNo} onClick={() => store.dispatch(createWorkerDetailsTab(
            {
                id: row.companyWorkerLogId,
                name: "CalisanDetayi",
                text:`${row.workerName}`
            }
      )) }>{row.identificationNo}</button>,
        button: true,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#fffff"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "ADI VE SOYADI",
        sortable: true,
        minWidth: "250px",
        sortField: "workerName",
        selector: row => row.workerName,
        cell: row => row.workerName,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "PERS. DEĞİL",
        sortable: true,
        minWidth: "180px",
        sortField: "accidentNo",
        selector: row => row.NotStaff,
        when: row => row.notStaff === "Evet",
        cell: row => row.notStaff,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "Kaza No",
        sortable: true,
        minWidth: "160px",
        sortField: "accidentNo",
        selector: row => row.accidentNo,
        when: row => row.notStaff === "Evet",
        cell: row => row.accidentNo,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KAZA TARİHİ",
        sortable: true,
        minWidth: "180px",
        sortField: "accidentDate",
        selector: row => row.accidentDate,
        cell: row => row.accidentDate,
        when: row => row.notStaff === "Evet",
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KİŞİ",
        minWidth: "150px",
        sortable: true,
        sortField: "numberOfAccidents",
        selector: row => row.numberOfAccidents,
        cell: row => row.numberOfAccidents,
        when: row => row.notStaff === "Evet",
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "BİLDİRİM ZAMANI",
        minWidth: "230px",
        sortable: true,
        sortField: "notificationDate",
        selector: row => row.notificationDate,
        when: row => row.notStaff === "Evet",
        cell: row => row.notificationDate,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "SİSTEM EKLEME",
        minWidth: "230px",
        sortable: true,
        sortField: "createdDate",
        selector: row => row.createdDate,
        when: row => row.notStaff === "Evet",
        cell: row => row.createdDate,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "NEREDE ÇALIŞIYOR",
        minWidth: "300px",
        sortable: true,
        sortField: "explanation2",
        selector: row => row.explanation ?? row.explanation2,
        //when: row => row.notStaff === 'Evet',
        cell: row => row.explanation ?? row.explanation2,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "MESLEK",
        minWidth: "250px",
        sortable: true,
        sortField: "job",
        selector: row => row.job,
        when: row => row.notStaff === "Evet",
        cell: row => row.job,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    }
]
