
// ** Reactstrap Imports
import {Card, CardBody} from 'reactstrap'

// ** Sub Components
import EntryOutTable from './subTabs/EntryOutTable'
import VisitsTable from './subTabs/VisitsTable'
import WorkAccidentsTable from './subTabs/WorkAccidentsTable'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'

function WorkerSubTabs({workerData, activeWorkerSubTab}) {
    // ** Render Tab Content on activeWorkerSubTab change
  const renderTabContent = (activeWorkerSubTab, workerData) => {
    switch (activeWorkerSubTab) {
        case 1:
            return <EntryOutTable workerData={workerData} />
        case 2:
            return <VisitsTable workerData={workerData}/>
        case 3:
            return <WorkAccidentsTable workerData={workerData}/>
        default:
            return <div>Lütfen görüntülemek istediğiniz veri tipini seçiniz.</div>
    }
}

  return (
          <Card>
              <CardBody>
                  <div className='info-container'>
                      {renderTabContent(activeWorkerSubTab, workerData)}
                  </div>
              </CardBody>
          </Card>
)
}

export default WorkerSubTabs