import { Badge } from 'reactstrap'

export const visitsTableColumns = [
    {
      name: 'Grup',
      sortable: false,
      minWidth: '100px',
      sortField: 'companyGroupName',
      selector: row => row.companyGroupName,
      cell: row => row.companyGroupName
    },
    {
      name: 'İşyeri Adı ve Sicil No',
      sortable: false,
      minWidth: '250px',
      sortField: 'name',
      selector: row => row.registirationNumber,
      cell: row => (
          <div className='d-flex justify-content-left align-items-center'>
              <div className='d-flex flex-column'>
                  <p className='user_name text-truncate text-body mb-0'>
                      <span className='fw-bolder'>{row.companyName}</span>
                  </p>
                  <small className='text-truncate text-muted mb-0'>{row.registirationNumber}</small>
              </div>
          </div>
      )
  },
    {
      name: 'Ad Soyad',
      sortable: false,
      minWidth: '250px',
      sortField: 'nameSurname',
      selector: row => row.nameSurname,
      cell: row => (
        <div className='d-flex justify-content-left align-items-center me-2'>
            <div className='d-flex flex-column'>
                <p className='user_name text-truncate text-body mb-0'>
                    <span className='fw-bolder'>{row.nameSurname}</span>
                </p>
                <small className='text-truncate text-muted mb-0'>{row.identificationNumber}</small>
            </div>
        </div>
    )
    },
    {
      name: 'Personel',
      minWidth: '75px',
      sortable: false,
      sortField: 'isStaffState',
      selector: row => row.isStaffState,
      cell: row => (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='d-flex flex-column'>
                <p className='user_name text-truncate text-body mb-0'>
                  <Badge color={row.isStaffState ?  'danger' : 'success'} className="fw-bolder">
                    {row.isStaffState ?  ' ' : 'EVET'}
                 </Badge>   
                </p>
            </div>
        </div>
    )
    },
    {
      name: 'Vaka',
      minWidth: '150px',
      sortable: false,
      sortField: 'caseName',
      selector: row => row.caseName,
      cell: row => row.caseName
    },
    {
      name: 'Rapor No',
      maxWidth: '300px',
      minWidth: '250px',
      sortable: false,
      sortField: 'reportNo',
      selector: row => row.reportNo,
      cell: row => (
              <p className='rounded-pill mb-0' style={{ backgroundColor: '#f8d7da', color: '#b71c1c', padding:'0 5px'}}>
                {row.reportNo}
              </p>
    )    },
    {
      name: 'Rapor Başlangıç Tarihi',
      minWidth: '150px',
      sortable: false,
      sortField: 'reportStartDate',
      selector: row => row.reportStartDate,
      cell: row => row.reportStartDate && row.reportStartDate.split(' ')[0].trim()
    },
    {
      name: 'Rapor Bitiş Tarihi',
      minWidth: '150px',
      sortable: false,
      sortField: 'reportEndDate',
      selector: row => row.reportEndDate,
      cell: row => row.reportEndDate && row.reportEndDate.split(' ')[0].trim()
    },
    {
      name: 'İzin Günü',
      minWidth: '50px',
      sortable: false,
      sortField: 'dayOff',
      selector: row => row.dayOff,
      cell: row => row.dayOff 
    }
  ]
  